import React from 'react'
import Layout from '../../../components/Layout/Layout'
import layoutStyles from '../../../components/Layout/Layout.module.scss'
import componentStyles from './MonitoringPage.module.scss'
import IndexContact from '../../index/IndexContact/IndexContact'
import ProductNavigation from '../../../components/ProductNavigation/ProductNavigation'
import MonitoringAdvantages from '../MonitoringAdvantages/MonitoringAdvantages'
import MonitoringTotal from '../../common/CommonTotal/CommonTotal'
import MonitoringTitle from '../../common/CommonPageTitle/CommonPageTitle'
import MonitoringCases from '../../common/CommonCases/CommonCases'
import MonitoringAnalytics from '../../common/CommonCases/CommonCases'
import { getConfigByLanguage } from '../../../utils/common-utils'

const MonitoringPage = ({ language, location }) => {
  const config = getConfigByLanguage(language)
  const { analytics, cases, seo, title, total } = config.monitoring
  const productNavigationClasses = {
    productNavigationClass: componentStyles.productNavigation,
  }
  const listClass = componentStyles.list
  const listItemClass = componentStyles.listItem
  const additionValueClass = componentStyles.additionValue
  const additionDescriptionValueClass = componentStyles.additionDescriptionValue

  return (
    <Layout location={location} seo={seo}>
      <div className={componentStyles.monitoring}>
        <div className={layoutStyles.container}>
          <ProductNavigation customClasses={productNavigationClasses} />
          <MonitoringTitle
            description={title.description}
            title={title.title}
          />
          <MonitoringAdvantages
            customClasses={{
              additionDescriptionValueClass,
              additionValueClass,
              listClass,
              listItemClass,
            }}
          />
          <MonitoringCases
            customClasses={{
              caseClass: componentStyles.case,
              containerClass: componentStyles.caseContainer,
              listClass,
              listItemClass,
            }}
            list={cases.list}
            title={cases.title}
          />
          <MonitoringAnalytics
            customClasses={{
              containerClass: componentStyles.analyticsContainer,
              listClass,
              listItemClass,
              // contentClass: componentStyles.analyticsContainer
            }}
            list={analytics.list}
            title={analytics.title}
          />
        </div>
        <MonitoringTotal dashed={false} list={total.list} title={total.title} />
      </div>
    </Layout>
  )
}

export default MonitoringPage
