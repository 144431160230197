import React, { useState } from 'react'
import { prepareHtml } from '../../../utils/text-to-html-formatter'
import { getConfigByLanguage } from '../../../utils/common-utils'
import { getCurrentLang } from '../../../utils/current-language-service'
import ValueLineDescription from '../../../components/ValueLineDescription/ValueLineDescription'
import ShadowedContainer from '../../../components/ShadowedContainer/ShadowedContainer'
import QuotedBlock from '../../../components/QuotedBlock/QuotedBlock'
import MonitoringTabs from '../../../components/MonitoringTabs/MonitoringTabs'
import componentStyles from './MonitoringAdvantages.module.scss'
import Line1 from '../../../assets/images/monitoring-line-1.svg'
import Line2 from '../../../assets/images/monitoring-line-2.svg'
import Line3 from '../../../assets/images/monitoring-line-3.svg'

import MonitoringAllHighlightEn from '../../../assets/images/en/en_Overview-Monitoring-all-highlight.svg'
import DetailImgEn from '../../../assets/images/en/en_Overview-Monitoring-correction-highlight-popup.svg'
import MonitoringCorrectionHighlightEn from '../../../assets/images/en/en_Overview-Monitoring-correction-highlight.svg'
import MonitoringFatalHighlightEn from '../../../assets/images/en/en_Overview-Monitoring-fatal-highlight.svg'

import MonitoringAllHighlightRu from '../../../assets/images/ru/ru_Overview-Monitoring-all-highlight.svg'
import DetailImgRu from '../../../assets/images/ru/ru_Overview-Monitoring-correction-highlight-popup.svg'
import MonitoringCorrectionHighlightRu from '../../../assets/images/ru/ru_Overview-Monitoring-correction-highlight.svg'
import MonitoringFatalHighlightRu from '../../../assets/images/ru/ru_Overview-Monitoring-fatal-highlight.svg'

const images = {
  en: {
    DetailImg: DetailImgEn,
    MonitoringAllHighlight: MonitoringAllHighlightEn,
    MonitoringCorrectionHighlight: MonitoringCorrectionHighlightEn,
    MonitoringFatalHighlight: MonitoringFatalHighlightEn
  },
  ru: {
    DetailImg: DetailImgRu,
    MonitoringAllHighlight: MonitoringAllHighlightRu,
    MonitoringCorrectionHighlight: MonitoringCorrectionHighlightRu,
    MonitoringFatalHighlight: MonitoringFatalHighlightRu
  }
}


const MonitoringAdvantages = ({ customClasses }) => {
  const [currentIndex, currentIndexSetter] = useState(0)
  const language = getCurrentLang()
  const config = getConfigByLanguage(language)
  const { advantages } = config.monitoring
  const {
    DetailImg,
    MonitoringAllHighlight,
    MonitoringCorrectionHighlight,
    MonitoringFatalHighlight
  } = images[language]
  const additionValueClass = customClasses && customClasses.additionValueClass
  const additionDescriptionValueClass = customClasses && customClasses.additionDescriptionValueClass

  const advantagesList = advantages.list.map(({addition, text, title}, index) => {
    const itemClass = componentStyles.listItem
    const preparedText = prepareHtml(text)
    let additionElement = null
    if (addition) {
      additionElement = (
        <div className={componentStyles.listItemAddition}>
          <ValueLineDescription language={language} customClasses={{
            descriptionValueClass: additionDescriptionValueClass,
            valueClass: additionValueClass
          }} value={addition.value} description={addition.description}/>
        </div>
      )
    }
    const titleElement = title ? (<h4 className={componentStyles.listItemTitle}>{title}</h4>) : null

    return (
      <div className={itemClass} key={index}>
        {titleElement}
        <div className={componentStyles.listItemContent}>
          <div className={componentStyles.listItemText}>
            <p dangerouslySetInnerHTML={{__html: preparedText}}/>
          </div>
          {additionElement}
        </div>
      </div>
    )
  })

  const preparedDetail = advantages.detail.map((text, index) => (
    <p className={componentStyles.detailText} key={index}>{text}</p>
  ))

  let highlight
  switch (currentIndex) {
    case 1:
      highlight = <MonitoringCorrectionHighlight className={componentStyles.statImg}/>
      break
    case 2:
      highlight = <MonitoringFatalHighlight className={componentStyles.statImg}/>
      break
    default:
      highlight = <MonitoringAllHighlight className={componentStyles.statImg}/>
      break
  }

  const tabSelectHandler = (currentIndex) => {
    currentIndexSetter(currentIndex)
  }

  return(
    <div className={componentStyles.advantages}>
      {advantagesList[0]}
      <div className={componentStyles.detailRow}>
        <ShadowedContainer className={componentStyles.detailShadowed}>
          <DetailImg className={componentStyles.detailImg}/>
        </ShadowedContainer>
        <div className={componentStyles.detailContent}>
          {preparedDetail}
          <Line1 className={componentStyles.detailLine1}/>
          <Line2 className={componentStyles.detailLine2}/>
          <Line3 className={componentStyles.detailLine3}/>
        </div>
      </div>
      <QuotedBlock language={language} customClasses={{
        containerClass: componentStyles.quoted
      }} text={advantages.quoted}/>
      {advantagesList[1]}
      <ShadowedContainer className={componentStyles.shadowed}>
        <MonitoringTabs language={language} tabSelectHandler={tabSelectHandler}>
          {highlight}
        </MonitoringTabs>
      </ShadowedContainer>
    </div>
  )
}

export default MonitoringAdvantages
